<script>
  import Circle from "./Circle.svelte";

  export let title;
  export let laAngle;
  export let metAngle;
  export let imageSrc;

  $: id = title.replace(" ", "_");

  let inputFile;
  let footImage;
  let uploadedImage = false;
  let imageDegrees = 0;
  let pointHX = 10;
  let pointHY = 2;
  let pointMX = 80;
  let pointMY = 2;
  let pointNX = 150;
  let pointNY = 2;
  let pointFX = 220;
  let pointFY = 2;

  /**
   * Calculates the angle (in radians) between two vectors pointing outward from one center
   * Long
   */
  function getLAAngle(pointF, pointN, pointM) {
    var b = Math.pow(pointN.x - pointF.x, 2) + Math.pow(pointN.y - pointF.y, 2),
      a = Math.pow(pointN.x - pointM.x, 2) + Math.pow(pointN.y - pointM.y, 2),
      c = Math.pow(pointM.x - pointF.x, 2) + Math.pow(pointM.y - pointF.y, 2);
    return Math.acos((a + b - c) / Math.sqrt(4 * a * b));
  }

  function getMetAngle(pointH, pointF, pointN) {
    var b = Math.pow(pointF.x - pointH.x, 2) + Math.pow(pointF.y - pointH.y, 2),
      a = Math.pow(pointF.x - pointN.x, 2) + Math.pow(pointF.y - pointN.y, 2),
      c = Math.pow(pointN.x - pointH.x, 2) + Math.pow(pointN.y - pointH.y, 2);
    return Math.acos((a + b - c) / Math.sqrt(4 * a * b));
  }

  function lineLength(point1, point2) {
    return Math.sqrt(
      Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y, 2)
    );
  }

  function rotateImage() {
    imageDegrees += 90;
  }

  $: laAngle = getLAAngle(
    { x: pointFX, y: -1 * pointFY },
    { x: pointNX, y: -1 * pointNY },
    { x: pointMX, y: -1 * pointMY }
  );

  $: laAngleDisplay = (laAngle * (180 / Math.PI)).toFixed(2);

  $: metAngle = getMetAngle(
    { x: pointHX, y: -1 * pointHY },
    { x: pointFX, y: -1 * pointFY },
    { x: pointNX, y: -1 * pointNY }
  );

  $: metAngleDisplay = (metAngle * (180 / Math.PI)).toFixed(2);

  function uploaded(e) {
    const file = inputFile.files[0];
    console.log(file);
    var reader = new FileReader();

    reader.onload = function(e) {
      imageSrc = e.target.result;
      uploadedImage = true;
    };
    reader.readAsDataURL(file);
  }

  // px1 - px2
  // py1 - py2
</script>

<style>
  .image-container {
    overflow: hidden;
    border: solid 1px black;
  }
  img {
    width: 100%;
    margin: 0 auto;
    display: block;
    overflow: hidden;
  }
  div.columns {
    position: relative;
    padding-bottom: 50px;
    padding: 5px;
  }
  input[type="file"] {
    display: none;
  }
  .circle-container {
    position: relative;
    padding: 0;
    height: 20px;
    width: 100%;
  }
</style>

<div class="six columns">
  <button class="primary-button">
    <label for={id}>{title}</label>
  </button>
  <button on:click={rotateImage} class="seconardy-button">rotate image</button>
  <input
    bind:this={inputFile}
    on:change={uploaded}
    class="u-full-width"
    type="file"
    {id} />
  <div class="image-container">
    <img
      bind:this={footImage}
      src={imageSrc}
      style="transform: rotate({imageDegrees}deg);"
      alt={title} />

  </div>
  <div class="row">
    <div class="columns six">LA Angle: {laAngleDisplay}°</div>
    <div class="columns six">1st Met Inclination: {metAngleDisplay}°</div>
  </div>
  <div class="circle-container">
    <Circle pointname="H" bind:left={pointHX} bind:top={pointHY} />
    <Circle pointname="M" bind:left={pointMX} bind:top={pointMY} />
    <Circle pointname="N" bind:left={pointNX} bind:top={pointNY} />
    <Circle pointname="F" bind:left={pointFX} bind:top={pointFY} />

  </div>
</div>
