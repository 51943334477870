<script>
  import disableScroll from "./disable-scroll.store.js";

  export let top;
  export let left;
  export let pointname;
  let isMoving = false;
  let offsetX;
  let offsetY;
  $: style =
    top && left ? `position: absolute; left: ${left}px; top: ${top}px;` : "";
  function onMouseMove(e) {
    if (isMoving) {
      top = offsetY + e.clientY;
      left = offsetX + e.clientX;
    }
  }

  function onTouchMove(e) {
    if (isMoving) {
      top = offsetY + e.touches[0].clientY;
      left = offsetX + e.touches[0].clientX;
    }
  }

  function onMouseUp() {
    isMoving = false;
    disableScroll.set(false);
  }

  function onMouseDown(e) {
    isMoving = true;
    offsetX = e.target.offsetLeft - e.clientX;
    offsetY = e.target.offsetTop - e.clientY;
  }

  function onTouchDown(e) {
    isMoving = true;
    disableScroll.set(true);
    offsetX = e.target.offsetLeft - e.touches[0].clientX;
    offsetY = e.target.offsetTop - e.touches[0].clientY;
  }
</script>

<style>
  span {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: solid blue 1px;
    cursor: move;
    display: inline-block;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    align-items: cneter;
    background-color: rgba(255, 255, 255, 0.479);
    padding-top: 1px;
    user-select: none;
  }
</style>

<svelte:window
  on:mouseup={onMouseUp}
  on:touchmove={onTouchMove}
  on:touchend={onMouseUp}
  on:touchcancel={onMouseUp}
  on:mousemove={onMouseMove} />
<span
  class="circle"
  {style}
  on:touchstart={onTouchDown}
  on:mousedown={onMouseDown}>
  {pointname}
</span>
