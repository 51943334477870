<script>
  import Foot from "./Foot.svelte";
  import disableScrollStore from "./disable-scroll.store.js";
  let leftLAAngleCorrected = 0;
  let leftMetAngleCorrected = 0;
  let rightLAAngleCorrected = 0;
  let rightMetAngleCorrected = 0;

  let leftLAAngleUncorrected = 0;
  let leftMetAngleUncorrected = 0;
  let rightLAAngleUncorrected = 0;
  let rightMetAngleUncorrected = 0;

  let showResults = false;

  disableScrollStore.subscribe(disable => {
    if (disable) {
      document.body.classList.add("disable-scroll");
      return;
    }
    document.body.classList.remove("disable-scroll");
  });

  function onShowResults() {
    showResults = true;
  }

  $: leftMetAngle = (
    (Math.sin(leftMetAngleUncorrected) / Math.sin(leftMetAngleCorrected)) *
    100
  ).toFixed(2);

  $: rightMetAngle = (
    (Math.sin(rightMetAngleUncorrected) / Math.sin(rightMetAngleCorrected)) *
    100
  ).toFixed(2);

  $: console.log({
    raw: rightMetAngleCorrected,
    tan: Math.tan(rightMetAngleCorrected),
    type: "rightMetAngleCorrected"
  });

  $: leftLAAngleDif = (
    leftLAAngleCorrected * (180 / Math.PI) -
    leftLAAngleUncorrected * (180 / Math.PI)
  ).toFixed(2);

  $: rightLAAngleDif = (
    rightLAAngleCorrected * (180 / Math.PI) -
    rightLAAngleUncorrected * (180 / Math.PI)
  ).toFixed(2);

  $: displayResult =
    !isNaN(leftMetAngle) &&
    !isNaN(rightMetAngle) &&
    leftMetAngle !== Infinity &&
    rightMetAngle !== Infinity;

  function savePdf() {
    window.print();
  }
</script>

<style>
  .space {
    margin-bottom: 50px;
  }
  button {
    max-width: 200px;
    display: block;
    margin: 0 auto;
  }
  h3 {
    font-size: 2em;
  }
</style>

<div class="container">
  <div class="row">
    <h1>LA Angle Test</h1>
  </div>

  <div class="row space">
    <Foot
      imageSrc="Left_Corrected.jpg"
      bind:laAngle={leftLAAngleCorrected}
      bind:metAngle={leftMetAngleCorrected}
      title="Upload Left Correct" />
    <Foot
      imageSrc="Right_Corrected.jpg"
      bind:laAngle={rightLAAngleCorrected}
      bind:metAngle={rightMetAngleCorrected}
      title="Upload Right Correct" />
  </div>
  <div class="row space">
    <Foot
      imageSrc="Left_Uncorrected.jpg"
      bind:laAngle={leftLAAngleUncorrected}
      bind:metAngle={leftMetAngleUncorrected}
      title="Upload Left Uncorrect" />
    <Foot
      imageSrc="Right_Uncorrected.jpg"
      bind:laAngle={rightLAAngleUncorrected}
      bind:metAngle={rightMetAngleUncorrected}
      title="Upload Right Uncorrect" />
  </div>

  {#if displayResult}
    <div class="row space">
      <div class="columns six">
        <h3>Left LA Angle Difference: {leftLAAngleDif}°</h3>
      </div>
      <div class="columns six">
        <h3>Right LA Angle Difference: {rightLAAngleDif}°</h3>
      </div>
    </div>

    <div class="row">
      <div class="columns six">
        <h3>Left % decrease PF tension {leftMetAngle}</h3>
      </div>
      <div class="columns six">
        <h3>Right % decrease PF tension {rightMetAngle}</h3>
      </div>
    </div>
    <div class="row">
      <div class="columns twelve">
        <button on:click={savePdf} class="button-primary">Save as PDF</button>
      </div>
    </div>
  {/if}
</div>
